










import {Vue, Component, Ref, Prop, Emit, Watch} from "vue-property-decorator";
import ScrollXType from './indexType';

@Component({name: "index.vue"})
export default class ScrollX extends Vue implements ScrollXType{
    StartPageX = 0 // 点下的坐标
    ScrollLeft = 0 // 点下的滚动值
    ScrollX = 0 // 滚动的间距
    ScrollBool = false // 是否航向滚动
    pullState = false

    handleTouchstart(e:any){
        this.StartPageX = e.changedTouches[0].pageX
        let scrollNum = this.scrollBox.scrollWidth - this.scrollBox.offsetWidth
        if ( scrollNum > 0 ){
            this.ScrollX = scrollNum
            this.ScrollLeft = this.scrollBox.scrollLeft
            this.ScrollBool = true
        }else{
            this.ScrollBool = false
        }
    }

    handleTouchmove(e:any){
        if ( !this.ScrollBool )return
        let PageX = e.changedTouches[0].pageX - this.StartPageX
        if ( PageX ){
            this.scrollBox.scrollLeft = this.ScrollLeft - PageX
        }else{
            this.scrollBox.scrollLeft = this.ScrollLeft +  Math.abs(PageX)
        }
    }

    handleTouchend(e:any){
        if ( !this.ScrollBool )return
        let scrollNum = this.scrollBox.scrollLeft
        if ( scrollNum < 1 )this.scrollBox.scrollLeft = 0;
        else if ( scrollNum >= this.ScrollX )this.scrollBox.scrollLeft = this.ScrollX;
    }

    handleScroll(e:any){
        let { scrollLeft } = e.target
        if (!this.getPullBool)return
        if (scrollLeft >= (this.ScrollX - this.getBottomNum) ) {
            if ( this.pullState ) return;
            this.pullState = true
            this.pullScrollX()
        }else{
            this.pullState = false
        }
    }

    @Ref("scrollBox")
    scrollBox!:any

    // 距离底部多少 抛出事件
    @Prop(Number)
    bottomNum!:number
    get getBottomNum(){
        return this.bottomNum || 20
    }

    // 是否开始 到底部扑出事件 默认关闭
    @Prop(Boolean)
    pullBool!:boolean
    get getPullBool(){
        return this.pullBool || false
    }

    // 是否开始左拉 加载组件
    @Prop(Boolean)
    upBottom!:boolean;
    get getUpBottom(){ return this.upBottom || false }

    @Emit("pullScrollX")
    pullScrollX(data?:any){
        return data
    }
}
