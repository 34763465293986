

















import {Vue, Component, Prop} from "vue-property-decorator";
import HotItemType from "./indexType";
import {randomBackImg} from "@/views/Group/GroupHall/util";

@Component({name: "GroupHallHotItem"})
export default class GroupHallHotItem extends Vue implements HotItemType{

    handleToPath(){
        this.$router.push({
            name:"groupSuc",
            query:{
                taskId:this.getData && this.getData.id,
                state:"hot"
            }
        })
    }

    get getUrl(){
        if ( this.getData ){
            let imgList = this.getData.img && this.getData.img.split(",");
            if ( imgList != null && imgList.length ){
                return imgList[0]
            }else{
                return randomBackImg()
            }
        }
        return randomBackImg()
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }
}
