

















import {Vue, Component, Watch} from "vue-property-decorator";
import HotGroupType from "./indexType";
import GroupHallHotItem from "@/views/Group/GroupHall/components/HotItem/index.vue";
import ScrollX from "@/components/ShareComponent/ScrollX/index.vue";
import { getHotGroup } from '../../Server';
import {GroupHallStore} from "@/views/Group/GroupHall/Store";

@Component({name: "GroupHallHotGroup",components:{ GroupHallHotItem,ScrollX }})
export default class GroupHallHotGroup extends Vue implements HotGroupType{
    rightIcon = require("@/assets/icon/Basics/Right.png")
    upDown = false
    StopUp = false
    List:any[] = []

    mounted(){
        this.List = GroupHallStore.getGroupHallHotList
        if (this.List.length < 1)this.handleUpData(true);
    }

    handleUpDownX(){
        if ( this.StopUp )return
        this.upDown = true
        let upData = GroupHallStore.getGroupHallHotUpData
        upData.pageNo += 1
        GroupHallStore.SetGroupHallHotUpData( upData )
        this.handleUpData()
    }

    handleUpData(bool?:boolean){
        getHotGroup().then(res=>{
            this.upDown = false
            if ( res.length < GroupHallStore.getGroupHallHotUpData.pageSize )this.StopUp = true;
            if ( bool )
                GroupHallStore.SetGroupHallHotList( res );
            else
                GroupHallStore.SetGroupHallHotList( GroupHallStore.getGroupHallHotList.concat(res) );
        }).catch(cat=>cat)
    }

    get getList(){ return GroupHallStore.getGroupHallHotList }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }
}
