




















import {Vue, Component} from "vue-property-decorator";
import GroupHallTitleType from "./indexType";

@Component({name: "GroupHallTitle"})
export default class GroupHallTitle extends Vue implements GroupHallTitleType{
    textIcon = require("@/views/Group/Img/text.png")
    writeIcon = require("@/views/Group/Img/write.png")
}
