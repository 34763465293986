
















import {Vue, Component, Watch} from "vue-property-decorator";
import GroupHallListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import GroupHallItem from "@/views/Group/components/GroupItem/index.vue";
import {getGroup} from "@/views/Group/GroupHall/Server";
import {GroupHallStore} from "@/views/Group/GroupHall/Store";

@Component({name: "GroupHallList",components:{ PullDownUpList,ListBottom,GroupHallItem }})
export default class GroupHallList extends Vue implements GroupHallListType{
    PullDown = false
    StopUp = false
    UpDownBool = true
    List:any[] = []

    mounted(){
        this.List = GroupHallStore.getGroupHallList
        if ( this.List.length < 1 )this.handleUpData(true);
    }

    handlePullDown(): void {
        this.PullDown = true
        this.StopUp = false
        let upData = GroupHallStore.getGroupHallUpData
        upData.pageNo = 1
        GroupHallStore.SetGroupHallUpData(upData)
        this.handleUpData(true)
    }

    handleUpDown(): void {
        if ( this.StopUp )return;
        let upData = GroupHallStore.getGroupHallUpData
        upData.pageNo += 1
        GroupHallStore.SetGroupHallUpData(upData)
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool?: boolean): void {
        getGroup().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < GroupHallStore.getGroupHallUpData.pageSize )this.StopUp = true;
            if ( bool )
                GroupHallStore.SetGroupHallList( res );
            else
                GroupHallStore.SetGroupHallList( GroupHallStore.getGroupHallList.concat(res) );
        })
    }

    get getList(){ return GroupHallStore.getGroupHallList }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }
}
