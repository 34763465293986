













import {Vue, Component} from "vue-property-decorator";
import GroupHallType from "./GroupHall";
import ZoomPage from "@/impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import GroupHallTitle from "@/views/Group/GroupHall/components/Title/index.vue";
import HotGroup from "@/views/Group/GroupHall/components/HotGroup/index.vue";
import GroupHallList from "@/views/Group/GroupHall/components/List/index.vue";

@Component({name: "GroupHall",components:{ HeadTop,GroupHallTitle,HotGroup,GroupHallList }})
export default class GroupHall extends ZoomPage implements GroupHallType{

    mounted(){
        this.loadingShow = false
    }
}
